<template>
    <div class="selector bi-hide-scroll">
        <component
            :is="component"
            v-for="(sport, index) in sports"
            :key="sport.id"
            :to="sport.route"
            :class="{
                'selector__button--active': sport.id === selectedSportId,
                'selector__button--hidden': index >= buttonsCount
            }"
            class="selector__button"
            @click="onSelectSportWithButton(sport.id)"
            @click.native="onSelectSportWithButton(sport.id)"
        >
            {{ sport.name }}
        </component>

        <BiSelect
            v-if="sportsInDropdown.length"
            :items="sportsInDropdown"
            :selected-value="selectedSportId"
            :placeholder-text="$t('more')"
            :is-active="isSportInDropdownSelected"
            @change="onSelectSportWithDropdown"
        />
    </div>
</template>

<i18n>
{
    "ru": {
        "more": "Еще"
    },
    "en": {
        "more": ""
    }
}
</i18n>

<script>
import BiSelect from '@/components/common/bi-select/index.vue'

export default {
    name: 'SportSelector',

    components: {
        BiSelect
    },

    props: {
        sports: {
            type: Array,
            required: true
        },

        selectedSportId: {
            type: String,
            required: true
        },

        navigateOnSelect: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        component () {
            return this.navigateOnSelect ? 'router-link' : 'button'
        },

        buttonsCount () {
            if (this.sports.length > 5) {
                return 4
            }

            return 5
        },

        sportsInDropdown () {
            return this.sports.slice(this.buttonsCount)
        },

        isSportInDropdownSelected () {
            return !!this.sportsInDropdown.find(sport => sport.id === this.selectedSportId)
        }
    },

    methods: {
        onSelectSportWithButton (sportId) {
            this.trackClick(sportId)

            if (!this.navigateOnSelect) {
                this.emitSportChanged(sportId)
            }
        },

        onSelectSportWithDropdown (sportId) {
            if (this.selectedSportId === sportId) {
                return
            }

            this.trackClick(sportId)

            if (this.navigateOnSelect) {
                this.goToSportRoute(sportId)
            } else {
                this.emitSportChanged(sportId)
            }
        },

        emitSportChanged (sportId) {
            this.$emit('sport-changed', sportId)
        },

        trackClick (sportId) {
            this.$pivik.event('sports', 'click_tab', sportId)
        },

        goToSportRoute (sportId) {
            const selectedSport = this.sports.find(({ id }) => id === sportId)
            const link = this.$router.resolve(selectedSport.route).href

            this.$router.push(link)
        }
    }
}
</script>

<style lang="stylus" scoped>
.selector
    display flex
    overflow auto
    margin-bottom .8rem

    +layout-md-and-up()
        overflow visible

    &__button
        button(4, 'outlined', 'text')
        white-space nowrap
        letter-spacing 0
        font-size 1.4rem
        font-weight $font-weight-regular

        &--active
            button(4, 'contained', 'primary')
            white-space nowrap
            letter-spacing 0
            font-size 1.4rem
            font-weight $font-weight-bold

        &--hidden
            display none

        &:not(:last-child)
            margin-right .8rem

</style>
