<template>
    <!-- явно навешиваем tabindex, чтобы на элементе сработал фокус,
    иначе keydown не стригерится -->
    <div
        class="ui-select"
        tabindex="0"
    >
        <div
            v-click-outside="hideDropdown"
            class="ui-select__value"
            :class="{
                'ui-select__value--disabled': isDisabled,
                'ui-select__value--is-focused': isDropdownVisible || isFocused,
                'ui-select__value--is-active': isActive,
                'ui-select__value--higher-layer': isDropdownVisible
            }"
            @click="toggleDropdown"
        >
            <slot name="input" />

            <span
                v-if="!hasInputSlot"
                :class="{ 'ui-select__name--placeholder': !value }"
                class="ui-select__name bi-body-2"
            >
                {{ selectedName }}
            </span>

            <IconArrowDown
                class="ui-select__arrow"
                :class="{
                    'ui-select__arrow--up': !isDropdownVisible,
                    'ui-select__arrow--down': isDropdownVisible
                }"
            />
        </div>

        <span
            v-if="isMobile"
            class="ui-select__native"
            :class="{ 'ui-select__native--visible': true }"
        >
            <select
                id="native-select"
                v-model="value"
                name="native-select"
            >
                <option
                    v-for="item in items"
                    :key="item.id"
                    :value="item.id"
                >
                    {{ item.name }}
                </option>
            </select>
        </span>

        <DropdownList
            v-if="showDropdown"
            :items="items"
            :focus-index="focusIndex"
            @choose-item="chooseItem"
        >
            <template #default="slotProps">
                <div class="dropdown-item bi-body-2">
                    <span
                        v-if="slotProps.item"
                        class="dropdown-item__name"
                        :class="{ 'dropdown-item__name--selected': slotProps.item.id === value }"
                    >
                        {{ trimText(slotProps.item.name) }}
                    </span>
                </div>
            </template>
        </DropdownList>
    </div>
</template>

<script>
/**
 * @module Select
 * @description универсальный компонент Select. Компонент отправляет в
 *              родительский компонент id опции, на которой сработало
 *              событие change. Select может как принимать значение от
 *              родителя, так и сам менять себе стейт. Ключи опций:
 *              id - id опции
 *              name - имя опции
 *              disabled - флаг, отражающий, была ли опция выбрана раньше
 *                         (имеет значение для кейса, когда с помощью
 *                         нескольких идентичных селектов мы даем возможность
 *                         выбрать несколько опций одновременно)
 */
import clickOutside from '@/directives/click-outside'
import DropdownList from '@/components/common/bi-dropdown-list/index.vue'
import IconArrowDown from '@/assets/svg/arrow-down.icon.svg'

const MAX_NAME_LENGTH = 100

export default {
    name: 'BiSelect',
    directives: {
        clickOutside
    },

    components: {
        DropdownList,
        IconArrowDown
    },

    props: {
        // опции в селекте
        items: {
            type: Array,
            default: () => []
        },

        // активен ли селект?
        isDisabled: {
            type: Boolean,
            default: false
        },

        // принудительный фокус
        isFocused: {
            type: Boolean,
            defaut: false
        },

        isActive: {
            type: Boolean,
            defaut: false
        },

        // текст плейсхолдера
        placeholderText: {
            type: String,
            default: 'Выберите'
        },

        // выбранное значение в списке
        selectedValue: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            value: '',
            focusIndex: 0,
            isDropdownVisible: false
        }
    },

    computed: {
        selectedItem () {
            return this.items[this.focusIndex]
        },

        showDropdown () {
            return this.items && this.isDropdownVisible && !this.isMobile
        },

        isMobile () {
            return ['xxs', 'xs', 'sm'].includes(this.$mq)
        },

        hasInputSlot () {
            return !!this.$slots.input
        },

        selectedName () {
            if (this.value && this.selectedItem) {
                return this.trimText(this.selectedItem.name)
            }

            return this.placeholderText
        }
    },

    watch: {
        items (arr) {
            this.focusIndex = this.getIndex(arr, this.selectedValue)
        },

        selectedValue (val) {
            this.value = val
            this.focusIndex = this.getIndex(this.items, val)
        },

        value (val) {
            this.$emit('change', val)
        }
    },

    created () {
        this.value = this.selectedValue
        this.focusIndex = this.getIndex(this.items, this.selectedValue)
    },

    methods: {
        /**
         * @description заменяем текущий выбранный элемент на новый по индексу опции
         * @param {Number} index индекс выбранного элемента списка
         */
        chooseItem (index) {
            if (!this.items[index].disabled) {
                this.focusIndex = index
                this.value = this.items[index].id
            }

            this.hideDropdown()
        },

        /**
         * @description показывает/скрывает выпадающее меню
         */
        toggleDropdown () {
            if (!this.isDisabled && !this.isMobile) {
                this.isDropdownVisible = !this.isDropdownVisible
            }
        },

        /**
         * @description скрывает выпадающее меню
         */
        hideDropdown () {
            this.isDropdownVisible = false
        },

        /**
         * @description определяет индекс элемента в списке опций по его id
         * @param {Array} arr  массив элементов
         * @param {String} val id выбранного элемента
         * @returns {Number} индекс элемента с заданным id
         */
        getIndex (arr, itemId) {
            let index = 0

            if (arr.length && itemId) {
                index = arr.findIndex(item => item.id === itemId)
            }

            return index
        },

        /**
         * @description Обрезает длинные названия
         * @returns {String} название, укороченное до максимальной длинны с многоточием
         */
        trimText (text) {
            if (text.length > MAX_NAME_LENGTH) {
                return `${text.substring(0, MAX_NAME_LENGTH - 2)}\u{2026}`
            }

            return text
        }
    }
}
</script>

<style lang="stylus" scoped>
.ui-select
    position relative
    height 100%
    outline none
    cursor pointer

    &__value
        position relative
        display flex
        align-items center
        box-sizing border-box
        height 100%
        min-height 3.2rem
        background-color $cl-white
        border-radius .8rem
        border .1rem solid $cl-secondary
        white-space nowrap
        transition background-color .4s, opacity .4s
        padding 0 1.6rem

        &:hover
            opacity .6

        &--error
            border-color $cl-error

        &--disabled
            pointer-events none
            cursor default
            opacity .3

            filter grayscale(1)

        &--is-focused
            &, &:hover
                border-color $cl-background-common
                background-color $cl-background-common

                & .ui-select__name
                    color $cl-primary
                    font-weight $font-weight-bold

                & .ui-select__arrow
                    color $cl-primary

        &--is-active
            background-color $cl-primary
            border 0

            & .ui-select__name
                white-space nowrap
                letter-spacing 0
                font-size 1.4rem
                font-weight $font-weight-bold
                color $cl-white

            & .ui-select__arrow
                color $cl-white

        &--higher-layer
            &, &:hover
                z-index 3

    &__image
        display block
        width 100%
        height 100%

        &-wrapper
            overflow hidden
            width 2.4rem
            height 2.4rem
            border-radius 50%
            margin-right .5rem

    &__name
        color $cl-text
        line-height 3rem

    &__arrow
        color $cl-text
        margin-left .8rem

        &--up,
        &--down
            width 1.2rem
            height @width

        &--up
            transform rotate(180deg)

    &__native
        position absolute
        z-index 2
        top 0
        display none
        width 100%
        height 100%

        & > select
            width 100%
            height 100%
            -webkit-appearance menulist-button
            opacity 0

        &--visible
            display block

.dropdown-item
    display flex
    align-items center
    box-sizing border-box
    width 100%
    height 100%
    padding 0 1.6rem
    height 4rem
    border-radius .8rem
    text-align left

    &:hover
        background-color $cl-background-common

    &__image
        display block
        width 100%
        height 100%

        &-wrapper
            overflow hidden
            width 2.4rem
            height 2.4rem
            margin-right .5rem
            border-radius 50%

    &__name
        overflow hidden
        width 100%
        white-space nowrap
        text-overflow ellipsis
        font-family $font-family-content
        font-size 1.6rem
        line-height 1.8rem
        color $cl-text

        &--selected
            color $cl-primary
            font-weight $font-weight-medium

</style>
