/**
 * @description директива для отслеживания клика вне элемента
 */
export default {
    bind (el, binding, vnode) {
        el.clickOutsideEvent = (e) => {
            if (!(el === e.target || el.contains(e.target))) {
                vnode.context[binding.expression](e)
            }
        }

        document.body.addEventListener('click', el.clickOutsideEvent)
    },

    unbind (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    }
}
