<template>
    <div class="sport-selector-disclaimer bi-body-2">
        {{ $t('disclaimer') }}
    </div>
</template>

<i18n>
{
    "ru": {
        "disclaimer": "Статистика, в том числе ROI и WR, рассчитывается только по прогнозам на футбол"
    },
    "en": {
        "disclaimer": ""
    }
}
</i18n>

<script>
export default {
    name: 'SportSelectorDisclaimer'
}
</script>

<style lang="stylus" scoped>
.sport-selector-disclaimer
    width 100%
    box-sizing border-box
    margin-bottom 1.6rem
    padding 1.2rem 1.6rem
    background-color $cl-text-dark
    color $cl-white
    border-radius .8rem

</style>
