<template>
    <svg
        class="donut"
        :height="size"
        :width="size"
    >
        <rect
            v-for="(section, index) in dataset"
            :key="index"
            :width="size - stroke"
            :height="size - stroke"
            :stroke="section.fill"
            :stroke-width="stroke"
            :stroke-dasharray="sectionStrokeDasharray(section.value)"
            :stroke-dashoffset="sectionStrokeDashoffset(index)"
            :rx="size / 2"
            :x="stroke / 2"
            :y="stroke / 2"
            class="donut__section"
        />
    </svg>
</template>

<script>

export default {
    name: 'DonutChart',

    props: {
        dataset: {
            type: Array,
            required: true
        },

        size: {
            type: Number,
            default: 160
        },

        stroke: {
            type: Number,
            default: 15
        }
    },

    computed: {
        totalValue () {
            return this.dataset.reduce((sum, current) => current.value + sum, 0)
        },

        diagramPerimeter () {
            return Math.PI * (this.size - this.stroke)
        }
    },

    methods: {
        sectionStrokeDasharray (value) {
            const sectionLength = this.sectionLength(value)

            // округление, чтобы залить возможную несостыковку
            // между секторами из-за дробных значений
            return `${Math.ceil(sectionLength)} ${this.diagramPerimeter - sectionLength}`
        },

        sectionStrokeDashoffset (index) {
            return -this.dataset.slice(0, index)
                .reduce((sum, current) => this.sectionLength(current.value) + sum, 0)
        },

        sectionLength (value) {
            return (value / this.totalValue) * this.diagramPerimeter
        }
    }
}

</script>

<style lang="stylus" scoped>
.donut__section
    transition stroke-dasharray .35s
    fill none

</style>
