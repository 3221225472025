var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-select",attrs:{"tabindex":"0"}},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideDropdown),expression:"hideDropdown"}],staticClass:"ui-select__value",class:{
            'ui-select__value--disabled': _vm.isDisabled,
            'ui-select__value--is-focused': _vm.isDropdownVisible || _vm.isFocused,
            'ui-select__value--is-active': _vm.isActive,
            'ui-select__value--higher-layer': _vm.isDropdownVisible
        },on:{"click":_vm.toggleDropdown}},[_vm._t("input"),(!_vm.hasInputSlot)?_c('span',{staticClass:"ui-select__name bi-body-2",class:{ 'ui-select__name--placeholder': !_vm.value }},[_vm._v("\n            "+_vm._s(_vm.selectedName)+"\n        ")]):_vm._e(),_c('IconArrowDown',{staticClass:"ui-select__arrow",class:{
                'ui-select__arrow--up': !_vm.isDropdownVisible,
                'ui-select__arrow--down': _vm.isDropdownVisible
            }})],2),(_vm.isMobile)?_c('span',{staticClass:"ui-select__native",class:{ 'ui-select__native--visible': true }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],attrs:{"id":"native-select","name":"native-select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.value=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.items),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v("\n                "+_vm._s(item.name)+"\n            ")])}),0)]):_vm._e(),(_vm.showDropdown)?_c('DropdownList',{attrs:{"items":_vm.items,"focus-index":_vm.focusIndex},on:{"choose-item":_vm.chooseItem},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('div',{staticClass:"dropdown-item bi-body-2"},[(slotProps.item)?_c('span',{staticClass:"dropdown-item__name",class:{ 'dropdown-item__name--selected': slotProps.item.id === _vm.value }},[_vm._v("\n                    "+_vm._s(_vm.trimText(slotProps.item.name))+"\n                ")]):_vm._e()])]}}],null,false,3400821441)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }