<template>
    <div>
        <SportSelectorDisclaimer />

        <div class="bi-block view-profile-stat__block bi-layout bi-layout-row">
            <div class="bi-flex-xs-100 bi-flex-sm-and-up-50 view-profile-stat__col">
                <div class="bi-layout bi-layout-row bi-gutter-16">
                    <div class="view-profile-stat__pie">
                        <DonutChart :dataset="dataset" />
                        <div class="view-profile-stat__pie-label">
                            <span class="view-profile-stat__value t-bold">
                                {{ profileStat.bets_count.total }}
                            </span>
                            <span>
                                {{ $tc('t-bets', profileStat.bets_count.total) }}
                            </span>
                        </div>
                    </div>

                    <div class="view-profile-stat__legend bi-flex-sm bi-layout bi-valign-center">
                        <div
                            v-if="profileStat.bets_count.total > 0"
                            key="noempty"
                            class="bi-layout bi-layout-column bi-gutter-8"
                        >
                            <div class="bi-layout bi-align-between">
                                <span>{{ $t('t-active') }}</span><span class="view-profile-stat__value t-bold bi-header-6">{{ active }}%</span>
                            </div>
                            <div class="bi-layout bi-align-between">
                                <span>{{ $t('t-win') }}</span><span class="view-profile-stat__value t-bold bi-header-6">{{ win }}%</span>
                            </div>
                            <div class="bi-layout bi-align-between">
                                <span>{{ $t('t-lose') }}</span><span class="view-profile-stat__value t-bold bi-header-6">{{ lose }}%</span>
                            </div>
                            <div class="bi-layout bi-align-between">
                                <span>{{ $t('t-returns') }}</span><span class="view-profile-stat__value t-bold bi-header-6">{{ returns }}%</span>
                            </div>
                        </div>
                        <div
                            v-else
                            key="empty"
                            class="bi-layout bi-layout-column bi-gutter-8"
                        >
                            <div class="bi-layout">
                                {{ $t('t-empty') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bi-flex-xs-100 bi-flex-sm-and-up-50 view-profile-stat__col bi-layout bi-valign-center">
                <div class="bi-layout bi-layout-column bi-gutter-8">
                    <div class="bi-layout bi-align-between">
                        <span>{{ $t('t-rate') }}</span><span class="view-profile-stat__value t-bold bi-header-6">{{ profileStat.total_bets_stat.avg_rate }}</span>
                    </div>
                    <div class="bi-layout bi-align-between">
                        <span>{{ $t('t-amount') }}</span><span class="view-profile-stat__value t-bold bi-header-6">{{ profileStat.total_bets_stat.avg_amount }}</span>
                    </div>
                    <div class="bi-layout bi-align-between">
                        <span>{{ $t('t-max_win') }}</span><span class="view-profile-stat__value t-bold bi-header-6">{{ profileStat.total_bets_stat.max_win }}</span>
                    </div>
                    <div class="bi-layout bi-align-between">
                        <span>{{ $t('t-max_lose') }}</span><span class="view-profile-stat__value t-bold bi-header-6">{{ profileStat.total_bets_stat.max_lose }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="bi-block view-profile-stat__block bi-layout bi-layout-row">
            <div class="bi-flex-xs-100 bi-flex-sm-and-up-50 view-profile-stat__col">
                <table class="table-simple table-simple--col-2-bold table-simple--col-3-bold">
                    <thead>
                        <tr>
                            <th>{{ $t('my-top') }}</th>
                            <th>{{ $t('bets') }}</th>
                            <th>ROI,%</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in userTopLines"
                            :key="index"
                        >
                            <template v-if="item">
                                <td>
                                    {{ item.line.name }}
                                </td>
                                <td>
                                    {{ item.count }}
                                </td>
                                <td>
                                    <UiRoi :value="item.roi || 0">
                                        {{ item.roi }}
                                    </UiRoi>
                                </td>
                            </template>
                            <template v-else>
                                <td>......</td>
                                <td>—</td>
                                <td>—</td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="bi-flex-xs-100 bi-flex-sm-and-up-50 view-profile-stat__col">
                <table class="table-simple">
                    <thead>
                        <tr>
                            <th>{{ $t('all-top') }}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in profileStat.allusers_top_lines"
                            :key="index"
                        >
                            <td>{{ item.line.name }}</td>
                            <td />
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<i18n>
{
    "ru": {
        "t-title": "%{user} — статистика прогнозов и ставок пользователя — Betting Insider",
        "t-empty": "Этот пользователь еще не сделал ни одной ставки",
        "t-active": "Незавершенные",
        "t-win": "Выигранные",
        "t-lose": "Проигранные",
        "t-returns": "Возврат",
        "t-rate": "Средний коэффициент",
        "t-amount": "Средняя сумма ставки",
        "t-max_win": "Максимальный выигрыш",
        "t-max_lose": "Максимальный проигрыш",
        "t-bets": "ставка | ставки | ставок",
        "all-top": "Топ 5 линий среди всех пользователей",
        "my-top": "Топ 5 моих линий",
        "bets": "Ставки"
    },
    "en": {
        "t-title": "%{user} — tips and bets stats — Betting Insider",
        "t-empty": "This user hasn't made any tips yet",
        "t-active": "Active",
        "t-win": "Won",
        "t-lose": "Lost",
        "t-returns": "Returned",
        "t-rate": "Average odds",
        "t-amount": "Average bet amount",
        "t-max_win": "Biggest win",
        "t-max_lose": "Biggest loss",
        "t-bets": "bet | bets",
        "all-top": "Top 5 overall",
        "my-top": "My top 5",
        "bets": "Bets"
    }
}
</i18n>

<script>
import { mapGetters } from 'vuex'
import { DEFAULT_LOCALE } from '@/constants'
import DonutChart from '@/components/common/bi-donut-chart/index.vue'
import SportSelectorDisclaimer from '@/components/common/SportSelector/SportSelectorDisclaimer.vue'

export default {
    name: 'ViewProfileStat',

    components: {
        SportSelectorDisclaimer,
        DonutChart
    },

    asyncData ({ store, route }) {
        return Promise.all([
            store.dispatch('profile/fetchUserStatistics', { loc: DEFAULT_LOCALE, id: route.params.id })
        ])
    },

    head () {
        return {
            title: this.title,
            meta: [
                { id: 'og:title', property: 'og:title', content: this.title }
            ]
        }
    },

    computed: {
        ...mapGetters('profile', ['item', 'stat']),

        title () {
            return this.$t('t-title', {
                user: this.profile.name
            })
        },

        profile () {
            return this.item({ id: this.$route.params.id })
        },

        profileStat () {
            return this.stat({ id: this.$route.params.id })
        },

        win () {
            return Math.round(
                (this.profileStat.bets_count.win * 100) / this.profileStat.bets_count.total
            )
        },

        lose () {
            return Math.round(
                (this.profileStat.bets_count.lose * 100) / this.profileStat.bets_count.total
            )
        },

        returns () {
            return Math.floor(
                (this.profileStat.bets_count.return * 100) / this.profileStat.bets_count.total
            )
        },

        active () {
            return Math.round(
                (this.profileStat.bets_count.active * 100) / this.profileStat.bets_count.total
            )
        },

        userTopLines () {
            return [...this.profileStat.user_top_lines, ...(new Array(5))].slice(0, 5)
        },

        dataset () {
            const dataset = []

            if (this.profileStat.bets_count.active > 0) {
                dataset.push({ value: this.profileStat.bets_count.active, fill: '#bac3c6' })
            }

            if (this.profileStat.bets_count.win > 0) {
                dataset.push({ value: this.profileStat.bets_count.win, fill: '#72B65A' })
            }

            if (this.profileStat.bets_count.lose > 0) {
                dataset.push({ value: this.profileStat.bets_count.lose, fill: '#E05555' })
            }

            if (this.profileStat.bets_count.return > 0) {
                dataset.push({ value: this.profileStat.bets_count.return, fill: '#929a9d' })
            }

            return dataset
        }
    }
}
</script>

<style lang="stylus" scoped>
    .view-profile-stat {
        &__block {
            padding 2rem 0
        }
        &__val {
            display inline-block
            min-width 4rem
        }
        &__col {
            padding 0 2rem
            box-sizing border-box

            .table-simple {
                td:nth-child(2),
                td:nth-child(3) {
                    font-family $font-family-content
                    width 15%
                }
            }

            &:last-child {
                border-left 0 none
                margin-top 2.5rem

                &::before {
                    content ""
                    display block
                    flex 1 0 100%
                    border-top 1px solid rgba($cl-secondary, 0.6)
                    margin-bottom 2rem

                    +layout-sm-and-up() {
                        display none
                    }
                }

                +layout-sm-and-up() {
                    border-left 1px solid rgba($cl-secondary, 0.4)
                    margin-top 0
                }
            }

            h4 {
                margin-top 0
                margin-bottom 2rem
            }
        }

        &__legend {
            flex 1 1 100%

            @media (min-width: 400px) {
                flex 1 1 auto
            }
        }

        &__value {
            font-family $font-family-content
        }

        &__pie {
            width 18rem
            height 16rem
            position relative
            margin 0 auto
            margin-bottom 2.5rem
            font-size 1rem

            &-label {
                position absolute
                z-index 5
                top 0.8rem
                bottom 0
                left 0
                right 0
                padding 0 1.2rem
                display flex
                flex-direction column
                justify-content center
                align-items center

                span {
                    display block
                    font-size 1.8em
                    &:first-child {
                        font-size 4.6em
                        font-weight $font-weight-bold
                        line-height 0.8
                    }
                }
            }

            >>> svg {
                display block
                position relative
                left .2rem
                z-index 10
                width 100%
                margin 0 auto
            }

            @media (min-width: 400px) {
                width 15.5rem
                margin-bottom 0
            }

            +layout-sm-and-up() {
                width 10.5rem
                font-size 0.7rem
            }

            @media (min-width: 700px) {
                width 15.5rem
                font-size 0.9rem
            }

            @media (min-width: 780px) {
                width 18rem
                font-size 1rem
            }

            +layout-md-and-up() {
                width 10.5rem
                font-size 0.7rem
            }

            @media (min-width: 1055px) {
                width 15.5rem
                font-size 0.9rem
            }

            @media (min-width: 1120px) {
                width 18rem
                font-size 1rem
            }

            +layout-xs() {
                width 18rem
                font-size 1rem
            }
        }
    }
</style>
