<template>
    <div class="dropdown-items-wrapper">
        <ul
            ref="listWrapper"
            class="dropdown-items"
        >
            <li
                v-for="(item, index) in items"
                :key="item.id"
                :data-id="`${item.id}`"
                :class="{
                    'dropdown-items__item--active': focusIndex === index,
                    'dropdown-items__item--disabled': focusIndex !== index && item.disabled
                }"
                class="dropdown-items__item"
                @click="chooseItem(index)"
                @mouseover="changeFocusIndex(index)"
            >
                <slot :item="item" />
            </li>
        </ul>
    </div>
</template>

<script>
/**
 * @module Dropdown
 * @description UI-Component Dropdown.
 */

export default {
    name: 'DropdownList',
    props: {
        // элементы выпадающего списка
        items: {
            type: Array,
            default: () => []
        },

        // индекс элемента в фокусе
        focusIndex: {
            type: Number,
            default: 0
        }
    },

    data () {
        return {
            baseClass: 'dropdown-items'
        }
    },

    watch: {
        /**
         * @description прокручивает выпадашку до активного элемента,
         *              если он не во вьюпорте
         */
        focusIndex () {
            // величина вертикального скролла
            const wrapperScrollTop = this.notReactive.wrapper.scrollTop
            // активный элемент
            const focusItem = this.notReactive.wrapper.children[this.focusIndex]
            // верхняя координата активного элемента относительно родителя
            const focusItemOffsetTop = focusItem.offsetTop
            // высота активного тега с учетом границ
            const focusItemHeight = focusItem.offsetHeight

            // величина вертикального скролла
            let verticalScroll = wrapperScrollTop
            // координата нижней границы активного элемента относительно нижней границы видимой части списка элементов
            const focusItemBottomOffset = focusItemOffsetTop - this.notReactive.wrapperHeight
                - wrapperScrollTop + focusItemHeight

            /**
             * если верхняя граница активного элемента выше верхней границы
             * видимой части списка элементов, то прокручиваем по вертикали
             * вверх до верхней границы активного элемента
             */
            if (focusItemOffsetTop < wrapperScrollTop) {
                verticalScroll = focusItemOffsetTop - wrapperScrollTop
                /**
                 * если нижняя граница активного элемента ниже нижней границы
                 * видимой части списка элементов, то прокручиваем по вертикали
                 * вниз так, чтобы активный элемент был в самом низу списка
                 */
            } else if (focusItemBottomOffset > 0) {
                verticalScroll = focusItemBottomOffset
                /**
                 * в противном случае активный тег находится в видимой части
                 * списка тегов и ничего не надо делать
                 */
            } else {
                return
            }

            this.notReactive.wrapper.scrollBy({
                top: verticalScroll,
                left: 0,
                behavior: 'smooth'
            })
        }
    },

    mounted () {
        // устанавливает нереактивные переменные, связанные с DOM
        this.notReactive = {
            previousIsBottomReached: false,
            // обертка выпадающего списка
            wrapper: this.$refs.listWrapper,
            // высота видимой части списка элементов
            wrapperHeight: this.$refs.listWrapper.clientHeight
        }

        // скроллит до выбранного ранее элемента
        if (this.focusIndex >= 0) {
            // величина вертикального скролла
            const wrapperScrollTop = this.notReactive.wrapper.scrollTop

            // переопределяем предыдущее значение величины скролла после каждого доскролла до активного элемента
            this.notReactive.previousScrollTop = wrapperScrollTop
            // активный элемент
            const focusItem = this.notReactive.wrapper.children[this.focusIndex]
            // верхняя координата активного элемента относительно родителя
            const focusItemOffsetTop = focusItem.offsetTop
            /**
             * прокручиваем по вертикали вверх до верхней границы
             * активного элемента
             */
            const verticalScroll = focusItemOffsetTop - wrapperScrollTop

            this.notReactive.wrapper.scrollBy({
                top: verticalScroll,
                left: 0,
                behavior: 'auto'
            })
        }
    },

    methods: {
        /**
         * @description           эмитит индекс выбранного элемента
         *  @param {Number} index индекс элемента
         */
        chooseItem (index) {
            this.$emit('choose-item', index)
        },

        /**
         * @description           эмитит индекс элемента, на котором
         *                        стоит мышка
         *  @param {Number} index индекс элемента
         */
        changeFocusIndex (index) {
            this.$emit('change-focus-index', index)
        }
    }
}
</script>

<style lang="stylus" scoped>
.dropdown-items
    position relative
    overflow-y auto
    list-style-type none
    max-height 42rem
    margin 1.6rem .8rem
    box-sizing border-box
    margin-block-start 1.6rem
    margin-block-end 1.6rem
    margin-inline-start .8rem
    margin-inline-end .8rem
    padding-inline-start 0

    &-wrapper
        position absolute
        z-index 20
        top 50%
        left 0
        overflow hidden
        width 28rem
        transform translateY(2.8rem)
        box-sizing border-box
        border-width .1rem
        border-color $cl-secondary
        border-style solid
        border-radius .8rem
        background-color $cl-white

    &__item
        display flex
        align-items center
        box-sizing border-box
        cursor pointer

        &--disabled
            color $cl-secondary-light
            pointer-events none

            &:hover
                background-color transparent

</style>
